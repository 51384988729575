import Head from 'next/head'
import { useRouter } from 'next/router'

export default function Seo({
  title,
  siteName,
  description,
  url,
  type,
  robots,
  image
}: {
  title?: string
  siteName?: string
  description?: string
  url?: string
  type?: string
  robots?: string
  image?: string
}) {
  const router = useRouter()
  const meta = {
    title: title || 'TRINITI',
    site_name: siteName || 'TRINITI',
    description:
      description || 'Triniti is a suite of tools that give you new ways to create and express yourself through music.',
    url: url || 'https://app.triniti.plus',
    // must be a valid url, not a path to a file in /public
    image: image || 'https://app.triniti.plus/triniti-card.png',
    type: type || 'website',
    robots: robots || 'follow, index'
  }

  return (
    <Head>
      <title>{meta.title}</title>
      <meta name="robots" content={meta.robots} />
      <meta content={meta.description} name="description" />
      <meta property="og:url" content={`${meta.url}${router.asPath}`} />
      <link rel="canonical" href={`${meta.url}${router.asPath}`} />
      {/* Open Graph */}
      <meta property="og:type" content={meta.type} />
      <meta property="og:site_name" content={meta.site_name} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:title" content={meta.title} />
      <meta name="image" property="og:image" content={`${router.basePath}${meta.image}`} />
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@th_clarence" />
      <meta name="twitter:title" content={meta.title} />
      <meta name="twitter:description" content={meta.description} />
      <meta name="twitter:image" content={meta.image} />
    </Head>
  )
}
