import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import cn from '@/lib/util/cn'
import Spinner from '@/components/Spinner'

const buttonVariants = cva(
  'group relative inline-flex items-center justify-center rounded-md text-sm rounded-3xl tracking-widest ' +
    'font-normal ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring ' +
    'focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 overflow-hidden text-ellipsis whitespace-nowrap break-words',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-primary/90',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline: 'border-2 border-primary bg-background',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
        dropdown: 'rounded-sm border border-input hover:bg-muted',
        fire: 'bg-black/30 text-white backdrop-blur-3xl enabled:hover:bg-black/40',
        frost: 'bg-white/20 text-white backdrop-blur-3xl enabled:hover:bg-white/30',
        neutral: 'bg-white/5 text-white backdrop-blur-3xl enabled:hover:bg-white/20',
        white:
          'text-cs-purple bg-white hover:enabled:bg-white/80 active:bg-white/80 disabled:bg-gray-200 hover:enabled:shadow-white-subtle active:enabled:bg-white/70'
      },
      size: {
        default: 'h-10 px-4 py-2 gap-2',
        sm: 'h-9 px-3 gap-1.5',
        lg: 'h-11 px-8 gap-2.5',
        icon: 'h-10 w-10'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'default'
    }
  }
)

const buttonGlowVariants = cva('absolute top-0 left-0 h-full w-full -z-10 rounded-3xl', {
  variants: {
    variant: {
      default: 'bg-primary',
      destructive: 'bg-destructive',
      outline: 'bg-primary',
      secondary: 'bg-secondary',
      ghost: 'display-none',
      link: 'bg-primary',
      dropdown: 'hidden',
      fire: 'bg-black/30',
      frost: 'bg-white/20',
      neutral: 'bg-white/5',
      white: 'bg-white'
    }
  },
  defaultVariants: {
    variant: 'default'
  }
})

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  isLoading?: boolean
  icon?: React.ReactElement
  as?: React.ElementType
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant = 'default',
      size = 'default',
      asChild = false,
      children,
      isLoading,
      disabled,
      icon,
      as,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : as ? as : 'button'
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        disabled={isLoading || disabled}
        {...props}
        >
        <div className={cn(buttonGlowVariants({ variant }))} />
        {isLoading ? <Spinner /> : icon}
        {children}
      </Comp>
    )
  }
)
Button.displayName = 'Button'

export default Button
