import { Permission, Role, ArtistInfo } from '@prisma/client'

export { Permission, Role }

export type UpdateArtistType = Omit<ArtistInfo, 'createdAt' | 'updatedAt'>

export type CreateArtistType = Pick<ArtistInfo, 'name'>

export type UUID = string

export type ApiError = {
  status: number
  message: string
}

export const isApiError = (arg: unknown): arg is ApiError => {
  if (!!arg && typeof arg === 'object' && 'status' in arg && 'message' in arg) return true
  return false
}

export enum Status {
  Loading = 'loading',
  Success = 'success',
  Error = 'error',
  Empty = 'empty',
  Idle = 'idle'
}

export type AuthStatus = 'authenticated' | 'loading' | 'unauthenticated'
