import React, { ReactNode } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { useSession } from 'next-auth/react'
import { nextTrpc } from '@/lib/trpc'
import ArtistSelector from '@/components/ArtistSelector'

import { MainNav } from '../main-nav'
import { UserNav } from '../user-nav'
import CreateArtistModal from '@/components/modals/CreateArtistModal'
import { CreateArtistModalProvider } from '@/lib/useCreateArtistModal'
import useLocalStorage from '@rehooks/local-storage'

export default function Layout({ children }: { children: ReactNode }) {
  const [ selectedArtistId, setSelectedArtistId ] = useLocalStorage<string>('selectedArtistId')

  const { data: me } = nextTrpc.user.me.useQuery()
  const { status } = useSession()

  // returning early to prevent UI stuttering
  if (status === 'loading') return null

  const isAuthed = status === 'authenticated'

  return (
    <CreateArtistModalProvider>
      <div
        id="HOME-LAYOUT"
        className="relative flex h-screen min-h-screen w-full flex-col justify-normal overflow-hidden bg-background/50 p-[72px] pb-0 pt-[60px] lg:min-h-screen lg:flex-row lg:px-8 lg:pt-[6rem]"
      >
        <CreateArtistModal />
        <Link href="/" className="absolute left-4 top-8 lg:left-8">
          <Image priority src="/triniti-wordmark.svg" alt="Triniti logo" width={64} height={8} />
        </Link>

        <div className="border- absolute right-4 top-4 lg:right-8">
          <div className="flex h-16 items-center px-4">
            <MainNav className="mx-6" />
            {isAuthed && <ArtistSelector />}
            {isAuthed &&
              <div className="ml-auto flex items-center space-x-4">
                {/* @ts-ignore */}
                <UserNav me={{ ...me }} />
              </div>
            }
          </div>
        </div>
        <div className="h-full w-full overflow-auto">{children}</div>
      </div>
    </CreateArtistModalProvider>
  )
}
