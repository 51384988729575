import { useRouter } from 'next/router'
import Button from './Button'

export default function ErrorScreen({
  text,
  showRetryButton,
  onClick
}: {
  text: string
  showRetryButton?: boolean
  onClick?: () => void
}) {
  const router = useRouter()

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-4 lg:h-screen">
      {text}
      {showRetryButton && <Button onClick={onClick ?? router.reload}>Try Again</Button>}
    </div>
  )
}
