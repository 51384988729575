import React, { ReactNode, useCallback, useEffect } from 'react'
import { useSession } from 'next-auth/react'
import Loader from '@/components/Loader'
import { useRouter } from 'next/router'
import { useMixpanelContext } from '@/lib/mixpanel/useMixpanel'
import { clientTrpc, nextTrpc } from '@/lib/trpc'
import { useSnackbar } from 'notistack'
import formatTrpcError from '@/lib/util/formatTrpcError'
import { captureException } from '@sentry/nextjs'

type FC = React.FC<{
  children?: ReactNode
}>

// AppWrapper handles a few things:
// - Setting up Mixpanel based on user auth state
// - Handling invites when in query string
export const AppWrapper: FC = ({ children }) => {
  const router = useRouter()
  const mixpanel = useMixpanelContext()
  const { data, status } = useSession({
    required: false,
    onUnauthenticated: () => {}
  })
  const { enqueueSnackbar } = useSnackbar()
  const trpcContext = nextTrpc.useContext()

  const handleInvite = useCallback(
    async (inviteId: string) => {
      try {
        if (status === 'authenticated') {
          const invite = await clientTrpc.invite.accept.mutate({ inviteId })
          localStorage.removeItem('inviteId')
          trpcContext.invalidate()
          localStorage.setItem('selectedArtistId', invite.artistId)
        } else {
          localStorage.setItem('inviteId', inviteId)
        }
      } catch (e) {
        captureException(e)
        const trpcError = formatTrpcError(e)
        console.error('Error handling invite', e)

        switch (true) {
          case trpcError.code === 'FORBIDDEN':
          case trpcError.code === 'NOT_FOUND':
            enqueueSnackbar(trpcError.message)
            break
          default:
            enqueueSnackbar('Invalid invite')
        }
      }
    },
    [ router, status ]
  )

  useEffect(() => {
    const { user } = data || {}
    if (!user) return
    mixpanel.identify(user.id)
    mixpanel.people.set({
      $distinct_id: user.id,
      $email: user.email,
      $name: user.name
      // any other attributes we can add from google login, etc. like $phone, etc.
    })
    mixpanel.alias(user.id, mixpanel.getDistinctId())
    mixpanel.track({ eventName: 'LoginComplete' })
  }, [ data?.user.id ])

  useEffect(() => {
    if (status === 'loading') return
    const inviteId = (router.query.inviteId as string) || localStorage.getItem('inviteId')

    if (inviteId) {
      handleInvite(inviteId)
    }
  }, [ router.query, status, handleInvite ])

  if (status === 'loading') return <Loader />
  return <>{children}</>
}
