import * as z from "zod"
import { ReleaseType } from "@prisma/client"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const BaseReleaseSchema = z.object({
  id: z.string(),
  baseOrMix: z.string(),
  releaseType: z.nativeEnum(ReleaseType),
  recordLabel: z.string(),
  coverArtUrl: z.string(),
  title: z.string(),
  preferredReleaseDate: z.date(),
  audioUrl: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  createdById: z.string(),
  artistId: z.string(),
  tracks: jsonSchema,
})
