const formatTrpcError = (err: any): { message: string; code?: string; errorsByKey?: Record<string, string> } => {
  if (err?.name !== 'TRPCClientError') {
    return {
      message: err?.message ?? err?.data?.message,
      code: undefined
    }
  }

  const error = err as any
  return {
    message: error?.data?.message as string,
    code: error?.data?.code as string,
    errorsByKey: error?.data.errorsByKey
  }
}

export default formatTrpcError
