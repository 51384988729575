import * as React from 'react'
import * as LabelPrimitive from '@radix-ui/react-label'
import { cva, type VariantProps } from 'class-variance-authority'
import cn from '@/lib/util/cn'

export const labelVariants = cva(
  'font-medium tracking-[.125rem] leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
  {
    variants: {
      size: {
        default: 'text-[10px]',
        sm: 'text-sm',
        md: 'text-base',
        lg: 'text-lg'
      }
    }
  }
)

export interface LabelProps
  extends React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>,
    VariantProps<typeof labelVariants> {
  asChild?: boolean
  isLoading?: boolean
}

const Label = React.forwardRef<React.ElementRef<typeof LabelPrimitive.Root>, LabelProps>(
  ({ className, size = 'default', ...props }, ref) =>
    <LabelPrimitive.Root ref={ref} className={cn(labelVariants({ size }), className)} {...props} />

)
Label.displayName = LabelPrimitive.Root.displayName

export default Label
