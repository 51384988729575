import mixpanel from 'mixpanel-browser'

const isEnabled = typeof process.env.NEXT_PUBLIC_MIXPANEL_API_KEY === 'string'

const inst = isEnabled
  ? mixpanel.init(
      process.env.NEXT_PUBLIC_MIXPANEL_API_KEY as string,
      {
        ignore_dnt: true,
        api_host: '/mp'
      },
      'triniti'
  )
  : null

export default inst
