import * as z from "zod"
import { Permission } from "@prisma/client"

export const InviteSchema = z.object({
  id: z.string(),
  email: z.string(),
  createdBy: z.string().nullish(),
  deletedBy: z.string().nullish(),
  acceptedBy: z.string().nullish(),
  permission: z.nativeEnum(Permission),
  artistId: z.string(),
  createdAt: z.date(),
  deletedAt: z.date().nullish(),
  acceptedAt: z.date().nullish(),
})
