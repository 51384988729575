import * as z from "zod"

export const ArtistInfoSchema = z.object({
  id: z.string(),
  name: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  billingEmail: z.string().nullish(),
  stripeAccountId: z.string().nullish(),
  firstName: z.string().nullish(),
  lastName: z.string().nullish(),
  phone: z.string().nullish(),
  bio: z.string().nullish(),
  managerName: z.string().nullish(),
  personalWebsite: z.string().nullish(),
  recordLabel: z.string().nullish(),
  instagram: z.string().nullish(),
  twitter: z.string().nullish(),
  facebook: z.string().nullish(),
  tiktok: z.string().nullish(),
  spotify: z.string().nullish(),
  appleMusic: z.string().nullish(),
  soundcloud: z.string().nullish(),
  bandcamp: z.string().nullish(),
  beatport: z.string().nullish(),
  mixcloud: z.string().nullish(),
})
