/*
TODO
- review the data shape
- review the key names (need to match db)
- go through the existing todos in file
- add more genres
*/
import { z } from 'zod'
import { File } from 'buffer'

import { Inputs, Panel } from './types'
import _ from 'lodash'
import { ArtistInfoSchema, MixReleaseSchema } from '@prisma-generated/zod'

function createSchemaDefinition(inputs: Inputs) {
  return Object.keys(inputs).reduce((acc, key) => {
    // @ts-ignore
    acc[key] = inputs[key].validation
    return acc
  }, {})
}

const dateValidation = z.string().refine((dateString) => !isNaN(Date.parse(dateString)), {
  message: "Invalid date format, should be 'YYYY-MM-DD'"
})

export const personalInfoInputs: Inputs = {
  firstName: {
    label: 'Name',
    placeholder: 'First Name',
    required: true,
    validation: z.string().min(1)
  },
  lastName: {
    label: 'Surname',
    placeholder: 'Last Name',
    required: true,
    validation: z.string().min(1)
  },
  name: {
    label: 'Performing Name',
    placeholder: 'ex: Flying Lotus',
    required: true,
    validation: z.string().min(1)
  },
  billingEmail: {
    label: 'Billing Email',
    placeholder: 'you@email.com',
    required: true,
    type: 'email',
    validation: z.string().email()
  },
  phone: {
    label: 'Phone',
    required: false,
    placeholder: '+1 (555) 555-5555',
    validation: z.string().optional()
  },
  bio: {
    label: 'Short Bio',
    placeholder: 'Tell us about yourself',
    as: 'textarea',
    required: false,
    validation: z.string().optional()
  },
  managerName: {
    label: `Manager's Name`,
    required: false,
    placeholder: 'If you have a manager',
    validation: z.string().optional()
  },
  recordLabel: {
    label: 'Record Label',
    required: false,
    placeholder: 'ex: Atlantic',
    validation: z.string().optional()
  },
  personalWebsite: {
    label: 'Personal Website',
    required: false,
    placeholder: 'ex: your-site.com',
    validation: z.string().optional()
  }
}

export const socialInputsBase: Inputs = {
  instagram: {
    label: 'Instagram',
    validation: z.string().optional()
  },
  twitter: {
    label: 'Twitter',
    validation: z.string().optional()
  },
  facebook: {
    label: 'Facebook',
    validation: z.string().optional()
  }
}

export const socialInputsMix: Inputs = {
  ...socialInputsBase,
  tiktok: {
    label: 'TikTok',
    validation: z.string().optional()
  },
  spotify: {
    label: 'Spotify',
    validation: z.string().optional()
  },
  appleMusic: {
    label: 'Apple Music',
    validation: z.string().optional()
  },
  soundcloud: {
    label: 'Soundcloud',
    validation: z.string().optional()
  },
  bandcamp: {
    label: 'Bandcamp',
    validation: z.string().optional()
  },
  beatport: {
    label: 'Beatport',
    validation: z.string().optional()
  },
  mixcloud: {
    label: 'Mixcloud',
    validation: z.string().optional()
  }
}

export const mixInfoInputs: Inputs = {
  language: {
    label: 'Language',
    required: true,
    as: 'select',
    options: [ 'English', 'Spanish', 'Mandarin', 'Swahili', 'Arabic', 'Hindi', 'French', 'German', 'Russian' ],
    validation: z.enum([ 'English', 'Spanish', 'Mandarin', 'Swahili', 'Arabic', 'Hindi', 'French', 'German', 'Russian' ])
  },
  parentalAdvisory: {
    label: 'Parental Advisory',
    required: true,
    as: 'select',
    options: [ 'CLEAN', 'EXPLICIT' ],
    validation: z.enum([ 'CLEAN', 'EXPLICIT' ])
  },
  mixType: {
    label: 'Mix Type',
    required: true,
    as: 'select',
    options: [ 'ARCHIVE', 'NEW_CONTENT', 'RADIO' ],
    validation: z.enum([ 'ARCHIVE', 'NEW_CONTENT', 'RADIO' ])
  },
  title: {
    label: 'Mix Title',
    placeholder: 'Your Mix Title',
    required: true,
    validation: z.string()
  },
  performanceCountryOrCity: {
    label: 'Performance Country or City',
    placeholder: 'ex: Morrison, CO',
    validation: z.string().optional()
  },
  performanceVenue: {
    label: 'Performance Venue',
    placeholder: 'ex: Red Rocks Amphitheatre',
    validation: z.string().optional()
  },
  recordLabel: {
    label: 'Release Label',
    placeholder: 'ex: Warp Records',
    required: true,
    validation: z.string().min(1)
  },
  primaryGenre: {
    label: 'Primary Genre',
    as: 'select',
    options: [
      'Ambient',
      'House',
      'IDM/Experimental',
      'Dance',
      'Bass',
      'Hardcore',
      'Breakbeat',
      'Downtempo',
      'Dubstep',
      'Electronic',
      'Electronica',
      'Garage',
      'Techno',
      'Trance',
      'Hip-Hop',
      'Industrial',
      `Jungle/Drum'n'bass`
    ],
    required: true,
    validation: z.enum([
      'Ambient',
      'House',
      'IDM/Experimental',
      'Dance',
      'Bass',
      'Hardcore',
      'Breakbeat',
      'Downtempo',
      'Dubstep',
      'Electronic',
      'Electronica',
      'Garage',
      'Techno',
      'Trance',
      'Hip-Hop',
      'Industrial',
      `Jungle/Drum'n'bass`
    ])
  },
  secondaryGenre: {
    label: 'Secondary Genre',
    as: 'select',
    options: [
      'Ambient',
      'House',
      'IDM/Experimental',
      'Dance',
      'Bass',
      'Hardcore',
      'Breakbeat',
      'Downtempo',
      'Dubstep',
      'Electronic',
      'Electronica',
      'Garage',
      'Techno',
      'Trance',
      'Hip-Hop',
      'Industrial',
      `Jungle/Drum'n'bass`
    ],
    validation: z
      .enum([
        'Ambient',
        'House',
        'IDM/Experimental',
        'Dance',
        'Bass',
        'Hardcore',
        'Breakbeat',
        'Downtempo',
        'Dubstep',
        'Electronic',
        'Electronica',
        'Garage',
        'Techno',
        'Trance',
        'Hip-Hop',
        'Industrial',
        `Jungle/Drum'n'bass`
      ])
      .optional()
  },
  performanceDate: {
    label: 'Performance Date',
    type: 'date',
    validation: dateValidation.optional()
  },
  preferredReleaseDate: {
    label: 'Preferred Release Date',
    required: true,
    // placeholder: 'Note: Apple has final decision on release date',
    type: 'date',
    validation: dateValidation.optional()
  }
}

export const releaseInfoInputs: Inputs = {
  releaseType: {
    label: 'Release Type',
    as: 'select',
    options: [ 'ALBUM', 'EP', 'SINGLE' ],
    required: true,
    validation: z.enum([ 'ALBUM', 'EP', 'SINGLE' ])
  },
  title: {
    label: 'Release Title',
    placeholder: 'ex: Cosmogramma',
    required: true,
    validation: z.string().min(1)
  },
  recordLabel: {
    label: 'Release Label',
    placeholder: 'ex: Warp Records',
    required: true,
    validation: z.string().min(1)
  },
  preferredReleaseDate: {
    label: 'Preferred Release Date',
    // placeholder: 'Preferred date for the release to be released. It must be noted that Apple has final decision on release date',
    type: 'date',
    required: true,
    validation: dateValidation
  }
}

export const mixTrackInputs: Inputs = {
  ['songName']: {
    label: 'Song Name',
    placeholder: 'ex: The Box',
    required: true,
    validation: z.string().min(1)
  },
  ['artistName']: {
    label: 'Artist Name',
    placeholder: 'ex: Roddy Ricch',
    required: true,
    validation: z.string().min(1)
  },
  ['recordLabel']: {
    label: 'Record Label',
    required: true,
    placeholder: 'ex: Atlantic',
    validation: z.string().min(1)
  },
  ['startTime']: {
    label: 'Track Start Time',
    placeholder: '0:00',
    required: true,
    validation: z.string().min(1)
  },
  ['endTime']: {
    label: 'Track End Time',
    placeholder: '0:00',
    required: true,
    validation: z.string().min(1)
  }
}

export const releaseTrackInputs: Inputs = {
  ['songName']: {
    label: 'Song Name',
    placeholder: 'ex: The Box',
    required: true,
    validation: z.string().min(1)
  },
  ['artistName']: {
    label: 'Artist Name',
    placeholder: 'Roddy Rich',
    required: true,
    validation: z.string().min(1)
  },
  ['genre']: {
    label: 'Song Genre',
    placeholder: 'ex: Dance',
    required: true,
    validation: z.string().min(1)
  },
  ['parentalAdvisory']: {
    label: 'Parental Advisory',
    required: true,
    as: 'select',
    options: [ 'CLEAN', 'EXPLICIT' ],
    validation: z.enum([ 'CLEAN', 'EXPLICIT' ])
  },
  ['producer']: {
    label: 'Producer(s)',
    placeholder: 'ex: Steven Ellison, Nigel Godrich',
    required: true,
    validation: z.string().min(1)
  }
}

export const writerInputs: Inputs = {
  ['artistName']: {
    placeholder: 'ex: Steven Ellison',
    label: "Artist's Name",
    required: true,
    validation: z.string().min(1)
  },
  ['publisher']: {
    placeholder: 'If none, put "Copyright Control"',
    label: 'Publisher',
    required: true,
    validation: z.string().min(1)
  },
  ['percentSplit']: {
    placeholder: 'ex: 40%',
    label: '% Split',
    required: true,
    validation: z.string().min(1)
  },
  ['performingRightsOrg']: {
    placeholder: 'ex: ASCAP',
    label: 'Performing Rights Organization',
    required: true,
    validation: z.string().min(1)
  }
}

const fileSchema = z.instanceof(File, { message: 'Required' })
const imageSchema = fileSchema.refine((file) => file.size === 0 || file.type.startsWith('image/'))

export const assetInputs: Inputs = {
  coverArt: {
    label: 'Album Cover Art',
    type: 'file',
    required: true,
    validation: imageSchema.refine((file) => file.size > 0, 'File size was 0, please upload a proper file!')
  },
  audio: {
    label: 'Audio Files',
    type: 'file',
    required: true,
    validation: fileSchema.refine((file) => file.size > 0, 'File size was 0, please upload a proper file!')
  }
}

export const socialInputs = _.merge(socialInputsBase, socialInputsMix)

export const artistPanel: Panel = {
  title: 'Artist Info',
  inputs: personalInfoInputs
}

export const personalInfoPanel: Panel = {
  title: 'Tell us about yourself',
  inputs: personalInfoInputs
}

export const socialsPanel: Panel = {
  title: 'Socials Info',
  inputs: socialInputs
}

export const socialPanelMix: Panel = {
  title: 'What are your socials?',
  inputs: socialInputsMix
}

export const socialPanelRelease: Panel = {
  title: 'What are your socials?',
  inputs: socialInputsBase
}

export const mixInfoPanel: Panel = {
  title: 'Mix Info: Metadata',
  inputs: mixInfoInputs
}

export const releaseInfoPanel: Panel = {
  title: 'Release Info: Metadata',
  inputs: releaseInfoInputs
}

export const mixTrackPanel: Panel = {
  title: 'Tracks',
  inputs: mixTrackInputs
}

export const releaseTrackPanel: Panel = {
  title: 'Tracks',
  inputs: releaseTrackInputs
}

export const assetPanel: Panel = {
  title: 'Audio & Visual Asset Submission',
  inputs: assetInputs
}

const personalInfoSchemaDefinition = createSchemaDefinition(personalInfoInputs)
const socialsDefinition = createSchemaDefinition(socialInputs)

export const artistFormSchema = z.object(personalInfoSchemaDefinition)
export const socialsFormSchema = z.object(socialsDefinition)

const mixSchemaDefinition = createSchemaDefinition(mixInfoInputs)
export const mixFormSchema = z.object(mixSchemaDefinition).extend({
  tracks: z.array(z.object(createSchemaDefinition(mixTrackInputs)))
})

const releaseTrackSchemaDefinition = z.array(
  z.object(createSchemaDefinition(releaseTrackInputs)).extend({
    writers: z.array(z.object(createSchemaDefinition(writerInputs)))
  })
)

const releaseSchemaDefinition = createSchemaDefinition(releaseInfoInputs)
export const releaseFormSchema = z.object(releaseSchemaDefinition).extend({
  tracks: releaseTrackSchemaDefinition
})

const ArtistInfoSchemaWithoutDates = ArtistInfoSchema.omit({
  createdAt: true,
  updatedAt: true
})

export const initialArtistFormValues: z.infer<typeof ArtistInfoSchemaWithoutDates> = {
  firstName: '',
  lastName: '',
  billingEmail: '',
  phone: '',
  bio: '',
  managerName: '',
  recordLabel: '',
  personalWebsite: '',
  id: '',
  name: '',
  stripeAccountId: '',
  instagram: '',
  twitter: '',
  facebook: '',
  tiktok: '',
  spotify: '',
  appleMusic: '',
  soundcloud: '',
  bandcamp: '',
  beatport: '',
  mixcloud: ''
}

// @ts-ignore
export const initialReleaseValues: z.infer<typeof BaseReleaseSchema> = {
  tracks: [
    {
      songName: '',
      artistName: '',
      genre: '',
      parentalAdvisory: '',
      producer: '',
      writers: [
        {
          artistName: '',
          publisher: '',
          percentSplit: '',
          performingRightsOrg: ''
        }
      ]
    }
  ],
  language: '',
  parentalAdvisory: '',
  title: '',
  preferredReleaseDate: '',
  releaseType: '',
  recordLabel: '',
  coverArtUrl: '',
  audioUrl: ''
}

// @ts-ignore
export const initialMixValues: z.infer<typeof MixReleaseSchema> = {
  tracks: [
    {
      songName: '',
      artistName: '',
      recordLabel: '',
      startTime: '',
      endTime: ''
    }
  ],
  language: '',
  title: '',
  performanceCountryOrCity: '',
  performanceVenue: '',
  primaryGenre: '',
  secondaryGenre: '',
  coverArtUrl: '',
  audioUrl: ''
}
