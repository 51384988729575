import * as z from "zod"
import { Permission } from "@prisma/client"

export const TeamUserSchema = z.object({
  artistId: z.string(),
  userId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  permission: z.nativeEnum(Permission),
})
