import React, { createContext, useContext, useState, Dispatch, SetStateAction } from 'react'

interface CreateArtistModalContextProps {
  isCreateArtistModalOpen: boolean
  setIsCreateArtistModalOpen: Dispatch<SetStateAction<boolean>>
}

const CreateArtistModalContext = createContext<CreateArtistModalContextProps>({
  isCreateArtistModalOpen: false,
  setIsCreateArtistModalOpen: () => {}
})

interface CreateArtistModalProviderProps {
  children: React.ReactNode
}

export const CreateArtistModalProvider: React.FC<CreateArtistModalProviderProps> = ({ children }) => {
  const [ isCreateArtistModalOpen, setIsCreateArtistModalOpen ] = useState<boolean>(false)

  return (
    <CreateArtistModalContext.Provider value={{ isCreateArtistModalOpen, setIsCreateArtistModalOpen }}>
      {children}
    </CreateArtistModalContext.Provider>
  )
}

export const useCreateArtistModal = () => useContext(CreateArtistModalContext)
