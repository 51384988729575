import { Dispatch, SetStateAction, useEffect, useState } from 'react'

const CLOSE_MODAL_TIMEOUT_DUR = 2000

export default function useDelayedModalClose({
  isOpen,
  setIsOpen
}: {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>> | ((isOpen: boolean) => void)
}) {
  const [ closeModalTimeout, setCloseModalTimeout ] = useState<NodeJS.Timeout>()

  useEffect(() => {
    if (!isOpen && closeModalTimeout) {
      clearTimeout(closeModalTimeout)
      setCloseModalTimeout(undefined)
    }
  }, [ isOpen, closeModalTimeout ])

  return () => {
    setCloseModalTimeout(
      setTimeout(() => {
        setIsOpen(false)
      }, CLOSE_MODAL_TIMEOUT_DUR)
    )
  }
}
