import * as z from "zod"
import { MixType, ParentalRating } from "@prisma/client"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const MixReleaseSchema = z.object({
  id: z.string(),
  baseOrMix: z.string(),
  mixType: z.nativeEnum(MixType),
  recordLabel: z.string(),
  coverArtUrl: z.string(),
  title: z.string(),
  subtitle: z.string().nullish(),
  preferredReleaseDate: z.date(),
  audioUrl: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  createdById: z.string(),
  artistId: z.string(),
  tracks: jsonSchema,
  language: z.string(),
  parentalAdvisory: z.nativeEnum(ParentalRating),
  performanceCountryOrCity: z.string().nullish(),
  performanceVenue: z.string().nullish(),
  primaryGenre: z.string(),
  secondaryGenre: z.string().nullish(),
  performanceDate: z.date().nullish(),
})
