import { Dispatch, ReactNode, SetStateAction, useEffect } from 'react'
import Label from '@/components/Label'
import { Status } from '@/lib/types'
import { Dialog, DialogContent, DialogDescription, DialogTitle } from '@/components/Dialog'
import { useMediaQuery } from 'react-responsive'
import { Drawer, DrawerContent, DrawerDescription, DrawerHeader, DrawerTitle } from '@/components/Drawer'
import cn from '@/lib/util/cn'

export default function Modal({
  isOpen,
  setIsOpen,
  title,
  subtitle,
  children,
  className,
  onClose,
  setStatus
}: {
  isOpen: boolean
  setIsOpen?: Dispatch<SetStateAction<boolean>> | ((isOpen: boolean) => void)
  title?: string
  subtitle?: string
  children?: ReactNode
  className?: string
  onClose?: (() => void) | null
  setStatus?: Dispatch<SetStateAction<Status>> | null
}) {
  const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' })
  useEffect(() => {
    if (!isOpen) {
      if (setStatus) setStatus(Status.Idle)
      if (onClose) onClose()
    }
  }, [ isOpen ])

  if (isDesktop) {
    return (
      <Dialog open={isOpen} onOpenChange={(isOpen) => !isOpen && setIsOpen?.(false)}>
        <DialogContent className={className}>
          {title &&
            <DialogTitle>
              <Label className="z-10 mt-0 w-fit">{title}</Label>
            </DialogTitle>
          }
          {subtitle &&
            <DialogDescription>
              <Label className="z-10 mt-0 w-fit">{subtitle}</Label>
            </DialogDescription>
          }
          {children}
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Drawer open={isOpen} onOpenChange={(isOpen) => !isOpen && setIsOpen?.(false)}>
      <DrawerContent className={cn('p-6 pt-0', className)}>
        <DrawerHeader className="p-0 text-left">
          {title &&
            <DrawerTitle>
              <Label className="z-10 mt-0 w-fit">{title}</Label>
            </DrawerTitle>
          }
          {subtitle &&
            <DrawerDescription>
              <Label className="z-10 mt-0 w-fit">{subtitle}</Label>
            </DrawerDescription>
          }
        </DrawerHeader>
        {children}
      </DrawerContent>
    </Drawer>
  )
}
