import React, { useCallback, useState } from 'react'
import Button from '@/components/Button'
import Input from '@/components/Input'
import Label from '@/components/Label'
import Modal from '@/components/modals/Modal'
import { Status } from '@/lib/types'
import useDelayedModalClose from '@/hooks/useDelayedModalClose'
import ErrorMessage from '../ErrorMessage'
import { clientTrpc, nextTrpc } from '@/lib/trpc'
import { useCreateArtistModal } from '@/lib/useCreateArtistModal'
import { useMixpanelContext } from '@/lib/mixpanel/useMixpanel'
import formatTrpcError from '@/lib/util/formatTrpcError'
import { captureException } from '@sentry/nextjs'
import useLocalStorage from '@rehooks/local-storage'
import { initialArtistFormValues } from '../static-data/form-data'

type CreateArtistModalProps = {
  onComplete?: (newArtistId: string) => void
}

const CreateArtistModal: React.FC<CreateArtistModalProps> = ({ onComplete }) => {
  const mixpanel = useMixpanelContext()

  const { isCreateArtistModalOpen: isOpen, setIsCreateArtistModalOpen: setIsOpen } = useCreateArtistModal()

  const [ name, setName ] = useState('')
  const [ errors, setErrors ] = useState<{ name?: string }>({})
  const [ status, setStatus ] = useState<Status>(Status.Idle)
  const closeModalWithDelay = useDelayedModalClose({ isOpen, setIsOpen })
  const trpcContext = nextTrpc.useContext()
  const [ selectedArtistId, setSelectedArtistId ] = useLocalStorage<string>('selectedArtistId')

  const { data: me } = nextTrpc.user.me.useQuery()
  const isAdmin = me?.role === 'ADMIN'

  const handleClose = useCallback(() => {
    setStatus(Status.Idle)
    setName('')
    setErrors({})
  }, [])

  const handleClick = async () => {
    mixpanel.track({
      eventName: 'AllArtistsPageCreateArtistSubmitButtonClick',
      data: {
        artistName: name
      }
    })

    try {
      setStatus(Status.Loading)
      const newArtist = await clientTrpc.artist.create.mutate({ name })
      const newTeamResult = await clientTrpc.user.team.create.mutate({
        artistId: newArtist.id,
        permission: isAdmin ? 'ADMIN' : 'READ',
        userId: me?.id
      })
      console.log('newTeamResult: ', newTeamResult, { artistId: newArtist.id, permission: 'READ', userId: me?.id })
      setSelectedArtistId(newArtist.id)
      trpcContext.artist.invalidate()
      setStatus(Status.Success)
      closeModalWithDelay()
      onComplete?.(newArtist.id)
      mixpanel.track({
        eventName: 'CreateArtist Completed',
        data: {
          artistName: name,
          artistId: newArtist.id
        }
      })
    } catch (err) {
      captureException(err)
      const trpcError = formatTrpcError(err)
      setStatus(Status.Idle)

      if (trpcError?.errorsByKey) setErrors(trpcError.errorsByKey)

      mixpanel.track({
        eventName: 'CreateArtist Failed',
        data: Object.assign({}, initialArtistFormValues, {
          artistName: name
        })
      })
    }
  }

  return (
    <Modal
      className="flex flex-col gap-6 lg:gap-8"
      setStatus={setStatus}
      onClose={handleClose}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="ADD ARTIST"
      >
      <div className="flex flex-shrink-0 flex-grow flex-col gap-2">
        <Label htmlFor="name">Name *</Label>
        <Input id="name" onChange={(e) => setName(e.target.value)} placeholder="Enter name" value={name} />
        <ErrorMessage animate isVisible={!!errors.name}>
          {errors.name}
        </ErrorMessage>
      </div>
      <div className="w-full">
        <ErrorMessage isVisible={status === Status.Error}>An error has occurred. Please try again later.</ErrorMessage>
        <Button
          isLoading={status === Status.Loading}
          disabled={status === Status.Loading}
          className="w-full lg:mt-1.5 lg:w-full"
          onClick={handleClick}
        >
          {status === Status.Success ? 'SUCCESS!' : 'CREATE'}
        </Button>
      </div>
    </Modal>
  )
}

export default CreateArtistModal
