import type { AppProps } from 'next/app'
import React from 'react'
import { Analytics } from '@vercel/analytics/react'
import Layout from '@/components/layout/Layout'
import { SessionProvider } from 'next-auth/react'
import { AppWrapper } from '@/components/layout/AppWrapper'
import { inter } from '@/lib/fonts'
import Seo from '@/components/Seo'
import { nextTrpc } from '@/lib/trpc'
import Head from 'next/head'

import '@/styles/globals.css'
import '@/styles/table.css'
import '@/styles/radix-slider.css'

import { MixpanelProvider } from '@/lib/mixpanel/useMixpanel'
import { SnackbarProvider } from 'notistack'
import ErrorBoundary from '@/components/ErrorBoundary'

function App({ Component, pageProps: { session, ...pageProps }, ipAddress }: AppProps & { ipAddress?: string }) {
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Head>
      {/* docs on globally applying a next font: https://nextjs.org/docs/pages/building-your-application/optimizing/fonts#apply-the-font-in-head */}
      <style jsx global>{`
        html {
          font-family: ${inter.style.fontFamily};
        }
      `}</style>
      <Analytics />
      <ErrorBoundary>
        <SessionProvider session={session}>
          <MixpanelProvider ipAddress={ipAddress}>
            <SnackbarProvider preventDuplicate>
              <Layout>
                <AppWrapper>
                  <Component {...pageProps} />
                </AppWrapper>
              </Layout>
            </SnackbarProvider>
          </MixpanelProvider>
        </SessionProvider>
      </ErrorBoundary>
      <Seo />
    </>
  )
}

export default nextTrpc.withTRPC(App)
