import * as z from "zod"

export const ReviewSchema = z.object({
  id: z.string(),
  status: z.string(),
  reviewedAt: z.date(),
  reviewedById: z.string(),
  notes: z.string().nullish(),
  baseReleaseId: z.string().nullish(),
  mixReleaseId: z.string().nullish(),
})
